import { Popover } from "@headlessui/react";
import Body from "components/Body";
import Icon from "components/Icon";
import classNames from "lib/classNames";
import useIsInViewport from "hook/useIsInViewport";
import React, { useRef } from "react";

export default function WorkflowBlock({ data }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  return (
    <section className="bg-secondary-light py-28 overflow-hidden">
      <div ref={ref} className="container px-4 mx-auto">
        <div className="flex flex-col md:flex-row xl:flex-col">
          <div className="w-full md:w-1/2 xl:w-full mb-12 md:mb-24 md:pr-16 xl:pr-0">
            <h2 className="xl:text-center">
              <span
                className="block font-important text-sm text-primary mb-4 transition-all"
                style={{
                  opacity: isInViewport ? 100 : 0,
                  transform: `translateY(${isInViewport ? 0 : "-1.5rem"})`,
                  transitionDuration: `${transitionDuration}s`,
                  transitionDelay: `0s`,
                }}
              >
                METROPACK
              </span>
              <span
                className="block font-important text-4xl transition-all"
                style={{
                  opacity: isInViewport ? 100 : 0,
                  transform: `translateY(${isInViewport ? 0 : "-1.5rem"})`,
                  transitionDuration: `${transitionDuration}s`,
                  transitionDelay: `${transitionDelay}s`,
                }}
              >
                {data.info}
              </span>
            </h2>

            {data.body && (
              <div className="prose xl:text-center mt-8">
                <Body value={data.body.processed} />
              </div>
            )}
          </div>
          <div className="w-full md:w-1/2 xl:w-full md:-ml-14 xl:ml-0">
            {data.field_paragraphs.length > 0 && (
              <div className="relative flex flex-col xl:flex-row xl:items-center xl:justify-between gap-8">
                <div
                  className="hidden xl:block absolute top-14 mx-12 w-auto h-4 bg-primary-dark rounded-lg -translate-y-1/2 transition-all"
                  style={{
                    left: 0,
                    right: isInViewport ? 0 : "100%",
                    transitionDuration: `${
                      (transitionDuration + transitionDelay) *
                        data.field_paragraphs.length -
                      transitionDelay
                    }s`,
                    transitionDelay: `0s`,
                  }}
                  aria-hidden="true"
                />
                <div
                  className="hidden xl:block absolute top-14 left-1/2 w-screen border-t border-dashed border-primary/30 -translate-x-1/2 -translate-y-1/2"
                  aria-hidden="true"
                />

                <div
                  className="block xl:hidden absolute inset-y-0 ml-12 mx-12 w-4 bg-primary-dark rounded-lg transition-all"
                  style={{
                    top: 0,
                    bottom: isInViewport ? 0 : "100%",
                    transitionDuration: `${
                      (transitionDuration + transitionDelay) *
                        data.field_paragraphs.length -
                      transitionDelay
                    }s`,
                    transitionDelay: `0s`,
                  }}
                  aria-hidden="true"
                />

                {data.field_paragraphs.map((paragraph, index) => {
                  const hasText = paragraph.field_text || false;
                  const thisTransitionDelay = transitionDelay * index;

                  return (
                    <Popover key={paragraph.id} className="relative">
                      <Popover.Button className={classNames(
                        "relative flex flex-row xl:flex-col items-center gap-8 outline-none",
                        hasText ? "cursor-pointer" : "cursor-default pointer-events-none"
                      )}>
                        <div
                          className="p-4 bg-primary rounded-lg text-primary transition-all"
                          style={{
                            opacity: isInViewport ? 100 : 0,
                            transform: `scale(${isInViewport ? 1 : 0.75})`,
                            transitionDuration: `${transitionDuration}s`,
                            transitionDelay: `${thisTransitionDelay}s`,
                          }}
                        >
                          <div className="p-6 bg-white rounded-full">
                            <Icon
                              name={paragraph.field_icon.field_component}
                              className="h-8 w-8"
                            />
                          </div>
                        </div>
                        <div
                          className="font-important text-left lg:text-center transition-all"
                          style={{
                            opacity: isInViewport ? 100 : 0,
                            transform: `translateY(${
                              isInViewport ? 0 : "-1.5rem"
                            })`,
                            transitionDuration: `${transitionDuration}s`,
                            transitionDelay: `${thisTransitionDelay + 0.2}s`,
                          }}
                        >
                          {paragraph.field_title}
                        </div>
                      </Popover.Button>
                      {hasText && (
                        <Popover.Panel
                          className={classNames(
                            "absolute top-1/2 left-32 bg-white shadow-md p-8 w-max max-w-xs -translate-y-1/2 rounded-md mb-1 z-10",
                            "xl:top-auto xl:bottom-full xl:left-1/2 xl:translate-y-0 xl:-translate-x-1/2 xl:mb-3"
                          )}
                        >
                          <div
                            className={classNames(
                              "absolute top-1/2 right-full border-r-white border-r-[10px] border-l-0 border-y-8 border-solid border-transparent -translate-y-1/2 text-sm",
                              "xl:top-full xl:right-auto xl:left-1/2 xl:border-r-transparent xl:border-r-8 xl:border-l-8 xl:border-t-white xl:border-t-[10px] xl:border-b-0 xl:-translate-y-0 xl:-translate-x-1/2"
                            )}
                          />
                          <Body value={paragraph.field_text.processed} />
                        </Popover.Panel>
                      )}
                    </Popover>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
